/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
// @mui material components
import Grid from "@mui/material/Grid";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";

// Vision UI Dashboard PRO React example components
import DefaultPricingCard from "examples/Cards/PricingCards/DefaultPricingCard";
import PropTypes from "prop-types";

function PricingCards({ prices, paymentCycle }) {
  const [starter, premium, enterprise] = prices;
  return (
    <VuiBox
      position="relative"
      zIndex={10}
      mt={{ xs: -35, sm: -32, md: -30, lg: -30, xl: -25, xxl: -25 }}
      px={{ xs: 1, sm: 0 }}
    >
      <Grid
        container
        spacing={3}
        sx={{ display: "flex", justifyContent: "center", flexDirection: "row" }}
      >
        <Grid item xs={12} lg={4} xl={3.3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "starter" }}
            price={{ currency: "$", value: starter, paymentCycle: paymentCycle }}
            specifications={[
              { label: "Fitting your needs", includes: true },
              { label: "Frontend Solution available", includes: true },
              { label: "Backend Solution available", includes: true },
              { label: "SaaS Licence", includes: true },
              { label: "Integration help", includes: true },
              { label: "Changes during Project", includes: false },
              { label: "Source Code", includes: false },
              { label: "Long-Term Support*", includes: false },
              { label: "Complete documentation", includes: false },
            ]}
            action={{
              type: "external",
              //route: "/",
              color: "info",
              label: "inquiry",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3.3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "premium" }}
            price={{ currency: "$", value: premium, paymentCycle: paymentCycle}}
            specifications={[
              { label: "Fitting your needs", includes: true },
              { label: "Frontend Solution available", includes: true },
              { label: "Backend Solution available", includes: true },
              { label: "SaaS Licence", includes: true },
              { label: "Integration help", includes: true },
              { label: "Changes during Project", includes: true },
              { label: "Source Code", includes: true },
              { label: "Long-Term Support*", includes: false },
              { label: "Complete documentation", includes: false },
            ]}
            action={{
              type: "external",
              //route: "/",
              color: "info",
              label: "inquiry",
            }}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={3.3}>
          <DefaultPricingCard
            badge={{ color: "dark", label: "enterprise" }}
            price={{ currency: "$", value: enterprise, paymentCycle: paymentCycle }}
            specifications={[
              { label: "Fitting your needs", includes: true },
              { label: "Frontend Solution available", includes: true },
              { label: "Backend Solution available", includes: true },
              { label: "SaaS Licence", includes: true },
              { label: "Integration help", includes: true },
              { label: "Changes during Project", includes: true },
              { label: "Source Code", includes: true },
              { label: "Long-Term Support", includes: true },
              { label: "Complete documentation", includes: true },
            ]}
            action={{
              type: "external",
              //route: "/",
              color: "info",
              label: "inquiry",
            }}
          />
        </Grid>
      </Grid>
    </VuiBox>
  );
}

// Typechecking props for the PricingCards
PricingCards.propTypes = {
  prices: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PricingCards;
