/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiInput from "components/VuiInput";
import VuiSwitch from "components/VuiSwitch";
import VuiButton from "components/VuiButton";
// Vision UI Dashboard custom components
import GradientBorder from "examples/GradientBorder";

// Vision UI Dashboard assets
import radialGradient from "assets/theme/functions/radialGradient";
import palette from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";

// Images
import illustrationImage from "assets/images/illustration-image.png";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

import { CircularProgress } from "@mui/material";



function Illustration() {
  //const [rememberMe, setRememberMe] = useState(false);
  var [name, setName] = useState(''); // name state
  var [invoiceId, setInvoiceId] = useState(''); // invoice id state
  var [fetching, setFetching] = useState(false);
  var [fetchedData, setFetchedData] = useState({});

  var [intialSetParam, setInitalSetParam] = useState(false);

  //const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const location = useLocation();

  // Parse the query parameters
  const params = new URLSearchParams(location.search);
  var paramName = params.get('name');
  var paramInvoice = params.get('invoice');

  useEffect(async () => {
    if (paramName && paramInvoice && !intialSetParam) {
      setInitalSetParam(true);
      
      name = paramName;
      invoiceId = paramInvoice;
      paramName = null;
      paramInvoice = null;
      await searchForInvoice(name, invoiceId)
    }
  }, [paramName, paramInvoice]);




 async function searchForInvoice(name, invoiceId) {
  if (name.trim() !== "" && invoiceId.trim() !== "") {
    setFetching(true);
    setFetchedData({}); // Kopie des vorhandenen fetchedData
    
    await fetchInvoiceData(name, invoiceId);
  }
}

async function fetchInvoiceData(name, invoiceId) {
  const payload = {
    name: name,
    invoice: invoiceId,
  };

  fetch('https://europe-west3-sean-home.cloudfunctions.net/checkForInvoice', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  })
    .then(response => response.json())
    .then(data => {
      // Verarbeiten Sie die Antwortdaten hier
      console.log('Response data:', data);

      if (data.data.length > 0) {

        const dateDue = new Date(data.data[0].due_date * 1000);
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

        const invoiceData = {
          ...data.data,
          found: true,
          dueDateString: `${dateDue.getDate()} ${months[dateDue.getMonth()]} ${String(dateDue.getFullYear()).slice(-2)}`
        };
        
        setFetchedData(invoiceData);
        setFetching(false);
        //console.log('invoiceData:', invoiceData);
        //console.log("disabled: " + !fetching && Object.keys(fetchedData).length > 0 && fetchedData.found);
      } else {
        setFetchedData({'found': false});
        setFetching(false);
      }

    })
    .catch(error => {
      // Verarbeiten Sie den Fehler hier
      //console.error('Error:', error);
      setFetchedData({'found': false});
      setFetching(false);
    });
}


const handleSearch = (event) => {
  event.preventDefault(); // prevent form from refreshing the page
  searchForInvoice(name, invoiceId); // call your function with the form inputs as arguments
}




  return (
    <IllustrationLayout
      title="Lookup Invoice"
      description="Pay your invoices seamlessly"
      fetching={fetching}
      fetchedData={fetchedData}
      illustration={{
        image: illustrationImage,
      }}
      top={20}>
      <VuiBox component="form" role="form" onSubmit={handleSearch}>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              First- and Lastname or Company
            </VuiTypography>
          </VuiBox>
          <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg} padding="1px" backgroundImage={radialGradient(palette.gradients.borderLight.main, palette.gradients.borderLight.state, palette.gradients.borderLight.angle)}>
            <VuiInput
              placeholder="Company LLC"
              value={name ? name : paramName}
              onChange={(e) => setName(e.target.value)}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              disabled={/*!fetching && Object.keys(fetchedData).length > 0 && fetchedData.found ||*/ fetching}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mb={2}>
          <VuiBox mb={1} ml={0.5}>
            <VuiTypography component="label" variant="button" color="white" fontWeight="medium">
              Invoice No.
            </VuiTypography>
          </VuiBox>
          <GradientBorder minWidth="100%" borderRadius={borders.borderRadius.lg} padding="1px" backgroundImage={radialGradient(palette.gradients.borderLight.main, palette.gradients.borderLight.state, palette.gradients.borderLight.angle)}>
            <VuiInput
              placeholder="C13AXXXX-XXXX"
              value={invoiceId ? invoiceId : paramInvoice}
              onChange={(e) => setInvoiceId(e.target.value)}
              sx={({ typography: { size } }) => ({
                fontSize: size.sm,
              })}
              disabled={/*!fetching && Object.keys(fetchedData).length > 0 && fetchedData.found ||*/ fetching}
            />
          </GradientBorder>
        </VuiBox>
        <VuiBox mt={4} mb={1}>
        <VuiButton color="info" fullWidth type="submit" sx={{ height: "30px" }} disabled={/*!fetching && Object.keys(fetchedData).length > 0 && fetchedData.found ||*/ fetching}>
          {fetching ? <CircularProgress color="grey" size="20px" /> : "Search" }
          </VuiButton>

        </VuiBox>
        <VuiBox mt={3} textAlign="center">
          <VuiTypography variant="button" color="text" fontWeight="regular">
            Not found?{" "}
            <VuiTypography sx={{
                '&:hover': {
                cursor: 'pointer',
                },
              }}
              variant="button"
              color="white"
              fontWeight="medium"
              onClick={(e) => {
                e.preventDefault();
                window.location.href = "mailto:inquiry@musizel.com?subject=Problem with Invoice";
              }}>
              Contact us
            </VuiTypography>
          </VuiTypography>
        </VuiBox>
      </VuiBox>
    </IllustrationLayout>
  );
}

export default Illustration;
