/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Vision UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import bgPricingPage from "assets/images/pricing-page-background.png";

import bg1 from "assets/images/background_header1.jpeg";
import bg2 from "assets/images/background_header2.jpeg";

// palette colors
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import borders from "assets/theme/base/borders";

import { IoCode, IoCodeSlash } from "react-icons/io5";

import seankmgLogo from "assets/images/seankmg_white_clear.png";

function Header({ tabValue, tabHandler }) {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const { borderRadius } = borders;

  return (
    <>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external", // external for _blank
          //route: "/#/",
          label: "inquiry",
          color: "dark",
        }}
        transparent
        light
      />
      <VuiBox
        //m={2}
        //pt={12}
        pb={20}
        position="relative"
        sx={{ height: { xs: "55vh", lg: "55vh", xl: "55vh", xxl: "55vh" } }}
        style={{
          overflow: "hidden",
          borderRadius: "0 0 1em 1em",
          overflowX: "hidden",
        }}>
        <VuiBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundSize: "cover",
            filter: "blur(15px)",
            webkitFilter: "blur(15px)",
            zIndex: 1,
            borderRadius: "1em",
          }}
        />
        <VuiBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          style={{
            background: "linear-gradient(transparent, black)",
            zIndex: 2,
            borderRadius: "1em",
          }}
        />

        <VuiBox position="relative" zIndex={3} pt={15}>
          <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={10} lg={5}>
              <VuiBox mb={0}>
              <img src={seankmgLogo} style={{ width: "90px"}}/>
                <VuiTypography fontSize={32} color="white" fontWeight="bold">
                <span style={{ fontFamily: 'VT323, monospace', marginLeft: "1px" }}>{"{coding}"}</span>
                </VuiTypography>
              </VuiBox>
              <VuiBox mb={0} textAlign="center" display="flex" justifyContent="center" flexDirection="row">
                <VuiTypography variant="body2" color="white" fontWeight="regular" sx={{ maxWidth: "340px" }}>
                <span style={{ fontFamily: 'VT323, monospace' }}>Having an idea? Let's get started.</span>
                </VuiTypography>
              </VuiBox>
              <Grid container item xs={12} sm={10} md={8} lg={7} sx={{ mx: "auto" }}>
                <VuiBox width="100%" mt={4} display="flex" justifyContent="center" alignItems="center"></VuiBox>
              </Grid>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>

      <VuiBox width="105rem" position="absolute" top={0} />

      <VuiBox position="relative" height={{ xs: "70vh", lg: "55vh", xl: "50vh", xxl: "50vh" }} style={{ borderRadius: "2em 2em 0 0" }} overflow="hidden" m={{ xs: 0, sm: 1, md: 5, lg: 15, lg: 15, xl: 15, xxl: 15}} pt={5} pb={20} mt={2}>
        <VuiBox
          position="absolute"
          width="100%"
          height="100%"
          sx={{
            background: `url(${bg1}) center/cover no-repeat`,
            transform: "rotate(180deg)",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            filter: "blur(5px)",
            webkitFilter: "blur(5px)",
            zIndex: 1
          }}
        />
        <VuiBox
          position="absolute"
          width="100%"
          height="100%"
          sx={{
            background: {
              sm: `linear-gradient(transparent 0%, transparent 10%, black 45%)`,
              lg: `linear-gradient(transparent 0%, transparent 20%, black 70%)`
          },
            overflowX: "hidden",
            zIndex: 2
          }}>
          <VuiBox width="105rem" position="absolute" top={0} />
          <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={10} lg={5}>
              <VuiBox mb={1} mt={{ sm: 5, lg: 3}}>
                <IoCodeSlash color="white" style={{ transform: "scale(2.0)", marginBottom: "20px" }} />
                <VuiTypography fontSize={{ md: 27, lg: 32}} color="white" fontWeight="bold">
                Software&nbsp;Developement
                </VuiTypography>
              </VuiBox>
              <VuiBox mb={2} textAlign="center" display="flex" justifyContent="center" flexDirection="row">
                <VuiTypography variant="body2" color="white" fontWeight="regular" sx={{ maxWidth: "340px" }}>
                  Let's develop a software solution that fits your needs.
                </VuiTypography>
              </VuiBox>
              <Grid container item xs={12} sm={10} md={8} lg={7} sx={{ mx: "auto" }}>
                <VuiBox width="100%" mt={5} display="flex" justifyContent="center" alignItems="center">
                  <AppBar position="static" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Tabs
                      value={tabValue}
                      onChange={tabHandler}
                      sx={{
                        maxWidth: "250px",
                        zIndex: "1000",
                        backgroundImage: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                        borderRadius: borderRadius.lg,
                        padding: "10px"
                      }}>
                      <Tab
                        id="monthly"
                        label={
                          <VuiBox py={0.5}>
                            <VuiTypography fontSize={10} color="white" fontWeight="bold">
                              MONTHLY
                            </VuiTypography>
                          </VuiBox>
                        }
                        sx={{ width: "50%" }}
                      />
                      <Tab
                        id="annual"
                        label={
                          <VuiBox py={0.5}>
                            <VuiTypography fontSize={10} color="white" fontWeight="bold">
                              {/*<s>*/}ONE-TME{/*</s>*/}
                            </VuiTypography>
                          </VuiBox>
                        }
                        sx={{ width: "50%" }}
                      />
                    </Tabs>
                  </AppBar>
                </VuiBox>
              </Grid>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>
    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
};

export default Header;
