/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import React from "react";

// @mui components
import { Card, Stack } from "@mui/material";

// Vision UI Dashboard assets
import balance from "assets/images/billing-background-balance.png";
import Graph from "assets/images/shapes/graph-billing.svg";

import palette from "assets/theme/base/colors";

// Vision UI Dashboard components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// React icons
import { FaEllipsisH } from "react-icons/fa";
import { MdOutlineDomain } from "react-icons/md";

const CreditBalance = ({amount, currency, width, title, dateText, paid, invoiceId, dueDate }) => {
  return (
    <Card sx={{ padding: "30px", paddingBottom: "0px", width: width ? width : "300"}}>
      <VuiTypography
                  textAlign={"start !important"}
                  mx="auto"
                  sx={({ typography: { size }, functions: { pxToRem } }) => ({
                    fontWeight: "bold",
                    fontSize: "12pt",
                    marginTop: "-15px",
                    paddingBottom: "15px"
                  })}
                  color="white">
                  #{invoiceId}
                </VuiTypography>
      <VuiBox display="flex" flexDirection="column">
        <VuiBox
          mb="32px"
          p="20px"
          display="flex"
          flexDirection="column"
          sx={{ backgroundImage: `url(${balance})`, backgroundSize: "cover", borderRadius: "18px" }}
        >
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="caption" color="white" fontWeight="medium" mr="auto">
              Billing amount
            </VuiTypography>
            <VuiTypography variant="caption" color="white" fontWeight="medium" mr="right" >
              Due on: {dueDate}
            </VuiTypography>
            {/*<FaEllipsisH color="white" size="18px" sx={{ cursor: "pointer" }} />*/}
          </VuiBox>
          <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
            <VuiTypography variant="h2" color="white" fontWeight="bold" mr="auto">
              {currency}{amount}
            </VuiTypography >
            <VuiBox component="img" src={Graph} sx={{ width: "58px", height: "20px" }} />
          </VuiBox>
          <VuiTypography
                sx={({ typography: { size }, functions: { pxToRem } }) => ({
                  fontWeight: "bold",
                    fontSize: "8pt",
                    paddingTop: "7px"
                  })}
                  color="white"
                  fontWeight="bold"
                  mr="auto">
              Status: { paid ?
              <span style={{color: "green"}}>Paid</span>
              :
              <span style={{color: "red"}}>Not Paid</span>
              }
            </VuiTypography>
        </VuiBox>
        {/*<VuiTypography color="text" variant="xxs" fontWeight="medium" mb="8px">
          Rechnungsposten
        </VuiTypography>
        <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
          <Stack direction="row" spacing="10px" mr="auto">
            <VuiBox
              display="flex"
              mr="10px"
              justifyContent="center"
              alignItems="center"
              sx={{
                background: "rgba(34, 41, 78, 0.7)",
                borderRadius: "50%",
                width: "42px",
                height: "42px",
              }}
            >
              <MdOutlineDomain color={palette.success.main} size="20px" />
            </VuiBox>
            <VuiBox display="flex" flexDirection="column">
              <VuiTypography color="white" variant="button" fontWeight="medium">
                {title}
              </VuiTypography>
              <VuiTypography color="text" variant="button" fontWeight="medium">
                {dateText}
              </VuiTypography>
            </VuiBox>
          </Stack>
          <VuiTypography variant="button" color="white" fontWeight="bold">
            {currency}{amount}
          </VuiTypography>
        </VuiBox>
            */}
      </VuiBox>
    </Card>
  );
};

export default CreditBalance;
