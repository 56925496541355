/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Pricing page components
import FaqCollapse from "layouts/pages/pricing-page/components/FaqCollapse";

import VuiButton from "components/VuiButton";

function Faq() {
  const [collapse, setCollapse] = useState(false);

  return (
    <VuiBox mt={{ sm: 10, xl: 16 }}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6} mb="40px">
          <VuiTypography fontSize={32} color="white" align="center" fontWeight="bold" gutterBottom>
            Frequently Asked Questions
          </VuiTypography>
          <VuiBox mb={2}>
            <VuiTypography
              fontSize={16}
              fontWeight="regular"
              color="white"
              align="center"
              color="text"
            >
              We have collected some frequently asked questions
              <br /> that might help you answering your questions
            </VuiTypography>
          </VuiBox>
        </Grid>
        <Grid item xs={12} md={10}>
          <FaqCollapse
            title="How do I order?"
            open={collapse === 1}
            onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
          >
            You can order ready-to-purchase products directly from the product page.
            Just add your preferred products to your cart and continue with checkout.<br />
            <br />
            If you want to purchase or get a quotation for a service we're offering, such as a software developement product fitting your needs, you can get a quotation by filing a requst on our pricing page.
          </FaqCollapse>
          <Divider light />
          <FaqCollapse
            title="How can i make the payment?"
            open={collapse === 2}
            onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
          >
            For our ready-to-purchase products, please continue the checkout process to make your payment for your selected product(s).
            We're currently offering various different payment methods, such as PayPal, Credit Card (Visa, Mastercard, American Express), and many more.
            <br /><br/>
            For a service we're offering, you'll get a quotation from us after you have filed your request. Based on your project needs, we'll send you an offer, which can then be accepted with you and be payed with the receiving of our invoice.<br />
            We're also offering various different payment methods, such as PayPal, Credit Card (Visa, Mastercard, American Express) and many more for our invoices.
          </FaqCollapse>
          <Divider light />
          <FaqCollapse
            title="How much time does it take to receive the order?"
            open={collapse === 3}
            onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
          >
            Ready-to-purchase products will be delivered at the latest of 14 days after purchase, whereas for digital products, your purchase will be usually delivered instantly via email.<br />
            <br/>
            For our services, such as software developement, please take a look at our specified estimated delivery time in our quotation or service contract. Each booked service will have an individual timeframe based on its needs.
          </FaqCollapse>
          <Divider light />
          <FaqCollapse
            title="Can I resell the products?"
            open={collapse === 4}
            onClick={() => (collapse === 4 ? setCollapse(false) : setCollapse(4))}
          >
            Of course not. The copyrights for all products, services and digital content on SKMG are being held by SKMG and those are not allowed to be published for commercial neither non-commercial usage nor distribution without prior explicit agreement from SKMG.
          </FaqCollapse>
          <Divider light />
          <FaqCollapse
            title="Where do I find the shipping details?"
            open={collapse === 5}
            onClick={() => (collapse === 5 ? setCollapse(false) : setCollapse(5))}
          >
            Your can find your specific shipping details inside our your order confirmation email or order details page linked inside of your order confirmation email.<br />
            <br />
            For our shipping terms, please refer to our Terms of Use section below.
            <br /><br />
            <VuiButton sx={{ width: "150px", borderRadius: "50px"}}>Terms of Use</VuiButton>
          </FaqCollapse>
          <Divider light />
        </Grid>
      </Grid>
    </VuiBox>
  );
}

export default Faq;
