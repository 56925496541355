/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import VuiButton from "components/VuiButton";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Vision UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Authentication layout components
import Footer from "layouts/authentication/components/Footer";

// Vision UI Dashboard PRO React theme functions
import colors from "assets/theme/base/colors";

// Vision UI Dashboard PRO React theme functions
import linearGradient from "assets/theme/functions/linearGradient";
import tripleLinearGradient from "assets/theme/functions/tripleLinearGradient";

import CreditBalance from "layouts/pages/account/billing/components/CreditBalance";

import DynamicFormIcon from "@mui/icons-material/DynamicForm";

// Images
import pattern from "assets/images/shapes/pattern-lines.svg";
import { useEffect, useState } from "react";
function IllustrationLayout({ color, header, title, description, illustration, children, top, fetching, fetchedData }) {
  useEffect(() => {
    if (!fetching && Object.keys(fetchedData).length > 0) {
      setIsFadingOut(true);
    } else {
      setIsFadingOut(false);
    }
  }, [fetching, fetchedData]);

  const { gradients } = colors;
  const [isFadingOut, setIsFadingOut] = useState(false);

  const handleBack = () => {
    setIsFadingOut(false);
  };

  function openPaymentLink() {
    if (fetchedData[0].hosted_invoice_url) {
      window.open(fetchedData[0].hosted_invoice_url, "_blank");
    }
  }

  function openDownloadInvoice() {
    if (fetchedData[0].invoice_pdf) {
      window.open(fetchedData[0].invoice_pdf, "_blank");
    }
  }

  return (
    <PageLayout background={tripleLinearGradient(gradients.cover.main, gradients.cover.state, gradients.cover.stateSecondary, gradients.cover.angle)}>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          route: "mailto:inquiry@musizel.com?subject=Problem with Invoice",
          label: "inquiry",
        }}
      />

      {!fetching && Object.keys(fetchedData).length > 0 ? (
        !fetchedData.found ? (
          <VuiBox
            height="100%"
            width="50vw"
            display={{ xs: "none", md: "block" }}
            position="absolute"
            top={0}
            right={0}
            sx={({ breakpoints }) => ({
              overflow: "hidden",
              [breakpoints.down("xl")]: {
                ml: "100px",
              },
              [breakpoints.down("lg")]: {
                display: "none",
              },
            })}
            zIndex={1000}>
            <VuiBox
              display={{ xs: "none", lg: "flex" }}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="calc(100%)"
              height="calc(100%)"
              position="relative"
              textAlign="center"
              variant="gradient"
              m={0}
              px={{ xs: 13, lg: 6, xl: 13 }}
              sx={{
                overflow: "hidden",
                background: "transparent",
                opacity: 1,
              }}>
              <DynamicFormIcon color="white" style={{ transform: "scale(2.0)", marginBottom: "-5px" }} />
              <br></br>
              <VuiTypography textAlign={"start !important"} variant="h4" fontWeight="bold" color="white" mb="10px">
                We couldn't find this invoice
              </VuiTypography>
              <VuiTypography
                textAlign={"start !important"}
                mx="auto"
                sx={({ typography: { size }, functions: { pxToRem } }) => ({
                  fontWeight: "regular",
                  fontSize: size.sm,
                })}
                color="white">
                Please try again
              </VuiTypography>
              <br></br>
            </VuiBox>
          </VuiBox>
        ) : (
          <VuiBox
            height="100%"
            width="50vw"
            display={{ xs: "none", md: "block" }}
            position="absolute"
            top={0}
            right={0}
            sx={({ breakpoints }) => ({
              overflow: "hidden",
              [breakpoints.down("xl")]: {
                ml: "100px",
              },
              [breakpoints.down("lg")]: {
                display: "none",
              },
            })}
            zIndex={1000}>
            <VuiBox
              display={{ xs: "none", lg: "flex" }}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              width="calc(100%)"
              height="calc(100%)"
              position="relative"
              textAlign="center"
              variant="gradient"
              m={0}
              px={{ xs: 13, lg: 6, xl: 13 }}
              sx={{
                overflow: "hidden",
                background: "transparent",
                opacity: 1,
              }}>
              <VuiTypography textAlign={"start !important"} variant="h4" fontWeight="bold" color="white" mb="10px">
                Hello, {fetchedData[0].customer_name.split(" ").some((word) => ["GmbH", "AG", "GbR", "LLC", "Ltd", "UG"].includes(word)) ? fetchedData[0].customer_name : fetchedData[0].customer_name.split(" ")[0]}!
              </VuiTypography>
              <VuiTypography
                textAlign={"start !important"}
                mx="auto"
                sx={({ typography: { size }, functions: { pxToRem } }) => ({
                  fontWeight: "regular",
                  fontSize: size.sm,
                })}
                color="white">
                {fetchedData[0].paid ? <span>This invoice has already been paid.</span> : <span>You can now pay this invoice.</span>}
              </VuiTypography>
              <br></br>

              <Grid item xs={12} md={12} xl={6}>
                <CreditBalance amount={fetchedData[0] ? parseFloat(fetchedData[0].total / 100).toFixed(2) : 0} currency={fetchedData[0] ? (fetchedData[0].currency === "eur" ? "€" : "$") : "UNK"} paid={fetchedData[0] ? fetchedData[0].paid : false} invoiceId={fetchedData[0] ? fetchedData[0].number : "Unknown"} dueDate={fetchedData ? `${fetchedData.dueDateString}` : 0} width="400px" />
              </Grid>

              <br></br>
              {fetchedData[0].paid ? (
                <VuiBox>
                <VuiButton
                  color="info"
                  onClick={(e) => {
                    e.preventDefault();
                    openDownloadInvoice();
                  }}
                  sx={{ height: "30px", width: "300px", zIndex: "1000" }}>
                  Download Invoice
                </VuiButton>
                <br /><br />
                <VuiTypography
                  textAlign={"center !important"}
                  mx="auto"
                  sx={({ typography: { size }, functions: { pxToRem } }) => ({
                    fontWeight: "bold",
                    fontSize: size.sm,
                  })}
                  color="white">
                  Everything is already done 🎉
                </VuiTypography>
                </VuiBox>
              ) : (
                <VuiBox>
                  <VuiButton
                    color="info"
                    onClick={(e) => {
                      e.preventDefault();
                      openDownloadInvoice();
                    }}
                    sx={{ height: "30px", width: "300px", zIndex: "1000" }}>
                    Download Invoice
                  </VuiButton>
                  <VuiButton
                    color="info"
                    onClick={(e) => {
                      e.preventDefault();
                      openPaymentLink();
                    }}
                    sx={{ height: "30px", width: "300px", zIndex: "1000", marginTop: "10px" }}>
                    Pay Now
                  </VuiButton>
                </VuiBox>
              )}
            </VuiBox>
          </VuiBox>
        )
      ) : (
        <VuiBox
          height="100%"
          width="50vw"
          display={{ xs: "none", md: "block" }}
          position="absolute"
          top={0}
          right={0}
          sx={({ breakpoints }) => ({
            overflow: "hidden",
            [breakpoints.down("xl")]: {
              ml: "100px",
            },
            [breakpoints.down("lg")]: {
              display: "none",
            },
          })}
          zIndex={0}>
          <VuiBox
            display={{ xs: "none", lg: "flex" }}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            width="calc(100%)"
            height="calc(100%)"
            position="relative"
            textAlign="center"
            variant="gradient"
            m={0}
            px={{ xs: 13, lg: 6, xl: 13 }}
            sx={{
              overflow: "hidden",
              background: linearGradient(gradients.info.main, gradients.info.state, gradients.info.deg),
              transition: "transform 1s ease-out, opacity 1s ease-out",
              transform: isFadingOut ? "translateX(100%)" : "none",
              opacity: 1,
            }}>
            {illustration.image && <VuiBox component="img" src={illustration.image} alt="chat-illustration" width="100%" maxWidth="100%" />}
          </VuiBox>
        </VuiBox>
      )}

      <VuiBox
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          alignItems: "center",
          maxWidth: "1044px",
          minHeight: "75vh",
          margin: "0 auto",
        }}>
        <VuiBox
          mt={top}
          mr="auto !important"
          sx={({ breakpoints }) => ({
            [breakpoints.down("xl")]: {
              ml: "30px",
            },
            [breakpoints.down("lg")]: {
              ml: "auto",
              mr: "auto !important",
            },
            [breakpoints.down("md")]: {
              maxWidth: "90%",
              pl: "7px",
              pr: "10px !important",
            },
          })}>
          <VuiBox pt={3} pr={3} pl={{ xs: 0, xl: 2 }} mx={{ xs: "auto !important", xl: "unset !important" }} maxWidth={"350px"}>
            <>
              <VuiBox mb="35px">
                <VuiTypography textAlign={"start"} variant="h3" fontWeight="bold" color="white" mb="10px">
                  {title}
                </VuiTypography>
                <VuiTypography
                  textAlign={"start !important"}
                  mx="auto"
                  sx={({ typography: { size }, functions: { pxToRem } }) => ({
                    fontWeight: "regular",
                    fontSize: size.sm,
                  })}
                  color="white">
                  {description}
                </VuiTypography>
              </VuiBox>
            </>
          </VuiBox>

          <VuiBox
            pl={{ xs: 0, xl: 1 }}
            pr={3}
            mb="50px"
            mx="auto"
            ml="auto !important"
            sx={({ breakpoints }) => ({
              mt: { top },
              maxWidth: "350px",
              [breakpoints.down("xl")]: {
                mr: "100px",
              },
              [breakpoints.up("lg")]: {
                mr: "95px",
              },
              [breakpoints.only("lg")]: {
                mr: "auto",
                ml: "auto !important",
              },
              [breakpoints.down("lg")]: {
                mr: "auto",
                ml: "auto !important",
              },
              [breakpoints.down("md")]: {
                mr: "7px",
                pr: "0px",
                pl: "0px",
              },
            })}>
            {children}
          </VuiBox>

          {/* MOBILE */}

          {!fetching && Object.keys(fetchedData).length > 0 ? (
            !fetchedData.found ? (
              <VuiBox
                height="100%"
                width="100%"
                display={{ xs: "block", sm: "block", md: "block", lg: "none", xl: "none" }}
                position="relative"
                top={0}
                right={0}
                sx={({ breakpoints }) => ({
                  overflow: "hidden",
                  [breakpoints.up("lg")]: {
                    display: "none",
                  },
                })}
                zIndex={1000}>
                <VuiBox
                  display={{ xs: "block", sm: "block", md: "block", lg: "none", xl: "none" }}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="calc(100%)"
                  height="calc(100%)"
                  position="relative"
                  textAlign="center"
                  variant="gradient"
                  m={0}
                  px={{ xs: 0, sm: 0, md: 0, lg: 6, xl: 13 }}
                  sx={{
                    overflow: "hidden",
                    background: "transparent",
                    opacity: 1,
                  }}>
                  <br></br>
                  <DynamicFormIcon color="white" style={{ transform: "scale(2.0)", marginBottom: "15px" }} />
                  <br></br>
                  <VuiTypography variant="h5" fontWeight="bold" color="white" mb="10px">
                    We couldn't find this invoice
                  </VuiTypography>
                  <VuiTypography
                    mx="auto"
                    sx={({ typography: { size }, functions: { pxToRem } }) => ({
                      fontWeight: "regular",
                      fontSize: size.sm,
                    })}
                    color="white">
                    Please try again
                  </VuiTypography>
                  <br></br>
                </VuiBox>
              </VuiBox>
            ) : (
              <VuiBox
                height="100%"
                width="100%"
                display={{ xs: "block", sm: "block", md: "block", lg: "none", xl: "none" }}
                position="relative"
                top={0}
                right={0}
                sx={({ breakpoints }) => ({
                  overflow: "hidden",
                  [breakpoints.up("lg")]: {
                    display: "none",
                  },
                })}
                zIndex={1000}>
                <VuiBox
                  display={{ xs: "block", sm: "block", md: "block", lg: "none", xl: "none" }}
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  width="calc(100%)"
                  height="calc(100%)"
                  position="relative"
                  textAlign="center"
                  variant="gradient"
                  m={0}
                  px={{ xs: 0, sm: 0, md: 0, lg: 6, xl: 13 }}
                  sx={{
                    overflow: "hidden",
                    background: "transparent",
                    opacity: 1,
                  }}>
                  <VuiTypography variant="h4" fontWeight="bold" color="white" mb="10px">
                    Hello, {fetchedData[0].customer_name.split(" ").some((word) => ["GmbH", "AG", "GbR", "LLC", "Ltd", "UG"].includes(word)) ? fetchedData[0].customer_name : fetchedData[0].customer_name.split(" ")[0]}!
                  </VuiTypography>
                  <VuiTypography
                    mx="auto"
                    sx={({ typography: { size }, functions: { pxToRem } }) => ({
                      fontWeight: "regular",
                      fontSize: size.sm,
                    })}
                    color="white">
                    {fetchedData[0].paid ? <span>This invoice has already been paid.</span> : <span>You can now pay this invoice.</span>}
                  </VuiTypography>
                  <br></br>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <CreditBalance amount={fetchedData[0] ? parseFloat(fetchedData[0].total / 100).toFixed(2) : 0} currency={fetchedData[0] ? (fetchedData[0].currency === "eur" ? "€" : "$") : "UNK"} paid={fetchedData[0] ? fetchedData[0].paid : false} invoiceId={fetchedData[0] ? fetchedData[0].number : "Unknown"} dueDate={fetchedData ? `${fetchedData.dueDateString}` : 0} width={{ xs: "340px", sm: "360px", md: "400px" }} />
                  </div>

                  <br></br>
                  {fetchedData[0].paid ? (
                    <VuiBox>
                    <VuiButton
                      color="info"
                      onClick={(e) => {
                        e.preventDefault();
                        openDownloadInvoice();
                      }}
                      sx={{ height: "30px", width: "300px", zIndex: "1000" }}>
                      Download Invoice
                    </VuiButton>
                    <br /><br />
                    <VuiTypography
                      mx="auto"
                      sx={({ typography: { size }, functions: { pxToRem } }) => ({
                        fontWeight: "bold",
                        fontSize: size.sm,
                      })}
                      color="white">
                      Everything is already done 🎉
                    </VuiTypography>
                  </VuiBox>
                  ) : (
                    <VuiBox>
                      <VuiButton
                        color="info"
                        onClick={(e) => {
                          e.preventDefault();
                          openDownloadInvoice();
                        }}
                        sx={{ height: "30px", width: "300px", zIndex: "1000" }}>
                        Download Invoice
                      </VuiButton>
                      <VuiButton
                        color="info"
                        onClick={(e) => {
                          e.preventDefault();
                          openPaymentLink();
                        }}
                        sx={{ height: "30px", width: "300px", zIndex: "1000", marginTop: "10px" }}>
                        Pay Now
                      </VuiButton>
                    </VuiBox>
                  )}
                </VuiBox>
              </VuiBox>
            )
          ) : (
            <span></span>
          )}

          {/* END MOBILE */}

          <Footer />
        </VuiBox>
      </VuiBox>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  color: "info",
  header: "",
  title: "",
  description: "",
  illustration: {},
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  fetching: PropTypes.bool,
  fetchedData: PropTypes.object,
};

export default IllustrationLayout;

// function CoverLayout({ color, header, title, description, image, top, cardContent, children }) {
//   const { gradients } = colors;
//   return (
//     <PageLayout
//       background={tripleLinearGradient(
//         gradients.cover.main,
//         gradients.cover.state,
//         gradients.cover.stateSecondary,
//         gradients.cover.angle
//       )}
//     >
//       <DefaultNavbar
//         routes={pageRoutes}
//         action={{
//           type: "external",
//           route: "https://creative-tim.com/product/vision-ui-dashboard-pro-react",
//           label: "buy now",
//         }}
//       />
//       <VuiBox
//         height="100%"
//         width="50vw"
//         display={{ xs: "none", md: "block" }}
//         position="absolute"
//         top={0}
//         left={0}
//         sx={({ breakpoints }) => ({
//           overflow: "hidden",
//           [breakpoints.down("xl")]: {
//             mr: "100px",
//           },
//           [breakpoints.down("lg")]: {
//             display: "none",
//           },
//         })}
//         zIndex={0}
//       >
//         <VuiBox
//           height="100%"
//           sx={{
//             backgroundImage: `url(${image})`,
//             backgroundSize: "cover",
//             backgroundPosition: "center",
//           }}
//         />
//       </VuiBox>
//       <VuiBox
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           position: "relative",
//           alignItems: "center",
//           maxWidth: "1044px",
//           minHeight: "75vh",
//           margin: "0 auto",
//         }}
//       >
//         <VuiBox
//           mt={top}
//           ml="auto !important"
//           sx={({ breakpoints }) => ({
//             [breakpoints.down("xl")]: {
//               mr: cardContent ? "50px" : "100px",
//             },
//             [breakpoints.down("lg")]: {
//               mr: "auto",
//               ml: "auto !important",
//             },
//             [breakpoints.down("md")]: {
//               maxWidth: "90%",
//               pr: "7px",
//               pl: "10px !important",
//             },
//           })}
//         >
//           <VuiBox pt={3} px={3} mx="auto !important" maxWidth={cardContent ? "400px" : "350px"}>
//             {!header ? (
//               <>
//                 <VuiBox mb="35px">
//                   <VuiTypography
//                     textAlign={cardContent ? "center" : "start"}
//                     variant="h3"
//                     fontWeight="bold"
//                     color={color}
//                     mb="10px"
//                   >
//                     {title}
//                   </VuiTypography>
//                   <VuiTypography
//                     textAlign={cardContent ? "center !important" : "start !important"}
//                     mx="auto"
//                     sx={({ typography: { size }, functions: { pxToRem } }) => ({
//                       fontWeight: "regular",
//                       fontSize: size.sm,
//                     })}
//                     color="white"
//                   >
//                     {description}
//                   </VuiTypography>
//                 </VuiBox>
//               </>
//             ) : (
//               header
//             )}
//           </VuiBox>
//           <VuiBox
//             px={3}
//             mb="50px"
//             mx="auto"
//             ml="auto !important"
//             sx={({ breakpoints }) => ({
//               mt: cardContent ? "60px" : { top },
//               maxWidth: cardContent ? "450px" : "350px",
//               [breakpoints.down("xl")]: {
//                 mr: cardContent ? "0px" : "100px",
//               },
//               [breakpoints.only("lg")]: {
//                 mr: "auto",
//                 ml: "auto !important",
//               },
//               [breakpoints.down("lg")]: {
//                 mr: "auto",
//                 ml: "auto !important",
//               },
//               [breakpoints.down("md")]: {
//                 mr: cardContent ? "auto !important" : "unset",
//                 pr: "7px",
//                 pl: cardContent ? "0px !important" : "10px !important",
//               },
//             })}
//           >
//             {children}
//           </VuiBox>
//           <Footer />
//         </VuiBox>
//       </VuiBox>
//     </PageLayout>
//   );
// }
