
import VuiButton from "components/VuiButton";

function PrivacyPolicyContent () {

    return (
        <>
        <p style={{ color: "white"}}>
            <br />
                At SKMG by Sean Gaenicke (SKMG), your privacy is important to us. This Privacy Policy explains what personal data we collect from you, how we use it, and your rights in relation to it.
                <br /><br />
                The use of the Internet pages of SKMG is possible without any indication of personal data. However, if a data subject wants to use special services of our enterprise via our website, processing of personal data could become necessary.
                If processing of personal data is necessary and there is no legal basis for such processing, we will generally obtain the consent of the data subject.<br />
                <br />
                The processing of personal data, such as the name, address, e-mail address, or telephone number of a data subject shall always be in line with the country-specific data protection regulations applicable to SKMG.
                By means of this data protection declaration, our enterprise would like to inform the public about the nature, scope and purpose of the personal data we collect, use and process.
                Furthermore, data subjects are informed of their rights by means of this data protection declaration.
                <br /><br />
                As the controller, SKMG has implemented numerous technical and organizational measures to ensure the most complete protection of personal data processed through this website.
                However, Internet-based data transmissions can always be vulnerable to security risks, so that absolute protection cannot be guaranteed.
                For this reason, every data subject is free to transmit personal data to us by alternative means, for example by telephone or email.
                <br /><br />


                <strong>1. DEFINITIONS</strong> <br />
                The data protection declaration of SKMG is based on the terms used by the European Directive and Ordinance when issuing the Data Protection Regulation (DS-GVO). Our privacy policy should be easy to read and understand for the public as well as for our customers and business partners. To ensure this, we would like to explain the terminology used in advance.<br /><br />
                We use the following terms, among others, in this data protection declaration:<br /><br />
                <strong>[A] PERSONAL DATA</strong><br />
                Personal data is any information relating to an identified or identifiable natural person (hereinafter "data subject"). An identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.<br /><br />
                <strong>[B] DATA SUBJECT</strong><br />
                Data subject means any identified or identifiable natural person whose personal data are processed by the controller.<br /><br />
                <strong>[C] PROCESSING</strong><br />
                Processing means any operation or set of operations which is performed upon personal data, whether or not by automatic means, such as collection, recording, organization, filing, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.<br /><br />
                <strong>[D] RESTRICTION OF PROCESSING</strong><br />
                Restriction of processing is the marking of stored personal data with the aim of limiting their future processing.<br /><br />
                <strong>[E] PROFILING</strong><br />
                Profiling is any type of automated processing of personal data that consists of using such personal data to evaluate certain personal aspects relating to a natural person, in particular to analyze or predict aspects relating to that natural person's job performance, economic situation, health, personal preferences, interests, reliability, behavior, location or change of location.<br /><br />
                <strong>[F] PSEUDONYMIZATION</strong><br />
                Pseudonymization is the processing of personal data in such a way that the personal data can no longer be attributed to a specific data subject without the use of additional information, provided that such additional information is kept separate and is subject to technical and organizational measures to ensure that the personal data is not attributed to an identified or identifiable natural person.<br /><br />
                <strong>[G] CONTROLLER OR PERSON RESPONSIBLE FOR PROCESSING</strong><br />
                The controller or data processor is the natural or legal person, public authority, agency or other body which alone or jointly with others determines the purposes and means of the processing of personal data. Where the purposes and means of such processing are determined by Union or Member State law, the controller or the specific criteria for its designation may be provided for under Union or Member State law.<br /><br />
                <strong>[H] PROCESSOR</strong><br />
                Processor means a natural or legal person, public authority, agency or other body which processes personal data on behalf of the Controller.<br /><br />
                <strong>[I] RECIPIENT</strong><br />
                Recipient means a natural or legal person, public authority, agency or other body to whom personal data are disclosed, whether or not a third party. However, public authorities that may receive personal data in the context of a specific investigation mandate under Union or Member State law shall not be considered as recipients.<br /><br />
                <strong>[J] THIRD PARTY</strong><br />
                Third party means a natural or legal person, public authority, agency or other body other than the data subject, the controller, the processor and the persons authorized to process the personal data under the direct responsibility of the controller or the processor.<br /><br />
                <strong>[K] CONSENT</strong><br />
                Consent shall mean any freely given indication of the data subject's wishes for the specific case in an informed and unambiguous manner, in the form of a statement or other unambiguous confirming
           
                <br /><br />
                <strong>2. NAME AND ADDRESS OF THE DATA CONTROLLER</strong><br />
                The controller within the meaning of the General Data Protection Regulation, other data protection laws applicable in the Member States of the European Union and other provisions of a data protection nature is:
                <br /><br />
                SKMG<br />
                SEAN GAENICKE<br />
                ALTSTR. 9<br />
                51063 COLOGNE<br />
                GERMANY<br />
                <br />
                TEL. [WA Business]: +49 1579 2611050<br />
                EMAIL: sean@musizel.com<br />
                WEBSITE: https://seankmg.de<br />
                <br />

                <strong>3. COOKIES</strong> <br />
                The internet pages of SKMG do not use cookies. Cookies are text files that are placed and stored on a computer system via an Internet browser.<br /> <br />


                <strong>4. COLLECTION OF GENERAL DATA AND INFORMATION</strong> <br />
                The website of SKMG collects a series of general data and information with each call-up of the website by a data subject or automated system. This general data and information is stored in the log files of the server. The following data may be collected: (1) the browser types and versions used, (2) the operating system used by the accessing system, (3) the website from which an accessing system accesses our website (so-called referrer), (4) the sub-websites that are accessed via an accessing system on our website, (5) the date and time of an access to the website, (6) an Internet protocol address (IP address), (7) the Internet service provider of the accessing system and (8) other similar data and information that serve to avert danger in the event of attacks on our information technology systems.<br /><br />
                When using these general data and information, SKMG does not draw any conclusions about the data subject. Rather, this information is needed (1) to deliver the contents of our website correctly, (2) to optimize the contents of our website and the advertising for these, (3) to ensure the long-term functionality of our information technology systems and the technology of our website, and (4) to provide law enforcement authorities with the information necessary for prosecution in the event of a cyber attack. Therefore, SKMG analyzes anonymously collected data and information on one hand, and on the other hand, with the aim of increasing the data protection and data security of our enterprise, to ensure an optimal level of protection for the personal data we process. The anonymous data of the server log files are stored separately from any personal data submitted by a data subject.<br /><br />
                <strong>4.1 USAGE OF CLOUDFLARE</strong><br />
                Our website uses services provided by Cloudflare, Inc. 101 Townsend St, San Francisco, CA 94107, USA. Cloudflare is an American company that provides a content delivery network (CDN), security features, and distributed domain name server services, aimed at enhancing the performance and security of websites. These services work by the storage of website content at various locations around the world to improve website load speeds, alongside providing security measures that protect websites from different kinds of attacks, such as Distributed Denial of Service (DDoS) attacks.<br />
                <br />
                The key goal of integrating these services into our website is to ensure the protection and integrity of data, to enhance the security of our website, and to guarantee an optimal browsing experience for users. The information processed may include your IP address and other information about your behavior on this website.<br />
                <br />
                More information about Cloudflare and its data protection measures can be found at: <a href="https://www.cloudflare.com/privacypolicy/">https://www.cloudflare.com/privacypolicy/</a><br />
                <br />
                <strong>8. CONTACT POSSIBILITY VIA THE INTERNET SITE</strong><br />
                Based on statutory provisions, the website of SKMG contains information that enables a quick electronic contact to our enterprise, as well as direct communication with us, which also includes a general address of the so-called electronic mail (e-mail address).<br />
                If a data subject contacts the controller by e-mail or by using a contact form, the personal data transmitted by the data subject will be stored automatically. Such personal data transmitted on a voluntary basis by a data subject to the controller will be stored for the purpose of processing or contacting the data subject. No disclosure of this personal data to third parties will take place.<br />

                <br />
                <strong>9. ROUTINE ERASURE AND BLOCKING OF PERSONAL DATA</strong><br />
                The controller processes and stores personal data of the data subject only for the period necessary to achieve the purpose of storage or where provided for by the European Directive and Regulation or other legislator in laws or regulations to which the controller is subject.<br />
                If the storage purpose ceases to apply or if a storage period prescribed by the European Directive and Regulation Maker or another competent legislator expires, the personal data will be routinely blocked or deleted in accordance with the statutory provisions.<br />
                <br />
                
                <strong>10. RIGHTS OF THE DATA SUBJECT</strong><br />
                <strong>[A] RIGHT TO CONFIRMATION.</strong> <br />
                Every data subject has the right, granted by the European Directive and Regulation, to obtain confirmation from the controller as to whether personal data concerning him or her are being processed. If a data subject wishes to exercise this right of confirmation, he or she may, at any time, contact any employee of the controller.<br />
                <br />
                <strong>[B] RIGHT OF ACCESS</strong><br />
                Any person concerned by the processing of personal data has the right granted by the European Directive and Regulation to obtain at any time from the controller, free of charge, information about the personal data stored about him or her and a copy of that information. Furthermore, the European Directive and Regulation has granted the data subject access to the following information:<br />

                * the purposes of processing<br />
                * the categories of personal data processed<br />
                * the recipients or categories of recipients to whom the personal data have been or will be disclosed, in particular in the case of recipients in third countries or international organizations<br />
                * if possible, the planned duration for which the personal data will be stored or, if this is not possible, the criteria for determining this duration<br />
                * the existence of a right to obtain the rectification or erasure of personal data concerning him or her, or to obtain the restriction of processing by the controller, or a right to object to such processing<br />
                * the existence of a right of appeal to a supervisory authority<br />
                * if the personal data are not collected from the data subject: Any available information about the origin of the data<br />
                <br />
                The existence of automated decision-making, including profiling, pursuant to Article 22(1) and (4) of the GDPR and, at least in these cases, meaningful information about the logic involved and the scope and intended effects of such processing for the data subject.<br />
                Furthermore, the data subject shall have the right to obtain information as to whether personal data have been transferred to a third country or to an international organization. If this is the case, the data subject also has the right to obtain information about the appropriate safeguards in connection with the transfer.<br />
                <br />
                If a data subject wishes to exercise this right of access, he or she may, at any time, contact an employee of the controller.<br />
                <br />
                <strong>[C] RIGHT OF RECTIFICATION</strong> <br />
                Every data subject affected by the processing of personal data has the right granted by the European Directive and Regulation to request the immediate rectification of inaccurate personal data concerning him or her.<br />
                Furthermore, the data subject has the right to request the completion of incomplete personal data - also by means of a supplementary declaration - taking into account the purposes of the processing.<br />
                <br />
                If a data subject wishes to exercise this right to rectify, he or she may, at any time, contact any employee of the controller. <br />
                <br />


                <strong>[D] RIGHT TO ERASURE (RIGHT TO BE FORGOTTEN).</strong><br />
                Any person concerned by the processing of personal data has the right granted by the European Directive and Regulation to obtain from the controller the erasure without delay of personal data concerning him or her, where one of the following grounds applies and insofar as the processing is no longer necessary:<br />
                <br /><br />
                * The personal data were collected or otherwise processed for such purposes for which they are no longer necessary.<br />
                * The data subject revokes his or her consent on which the processing was based pursuant to Art. 6(1)(a) DS-GVO or Art. 9(2)(a) DS-GVO and there is no other legal basis for the processing.<br />
                * The data subject objects to the processing pursuant to Article 21(1) DS-GVO and there are no overriding legitimate grounds for the processing, or the data subject objects to the processing pursuant to Article 21(2) DS-GVO.<br />
                * The personal data have been processed unlawfully.<br />
                * The erasure of the personal data is necessary for compliance with a legal obligation under Union or Member State law to which the controller is subject.<br />
                * The personal data has been collected in relation to information society services offered pursuant to Article 8(1) DS-GVO.<br />
                <br />
                If one of the aforementioned reasons applies, and a data subject wishes to arrange the erasure of personal data stored by SKMG, he or she may, at any time, contact any employee of the controller.<br />
                The employee of SKMG shall arrange for the erasure request to be complied with immediately.<br />
                <br />
                If the personal data was made public by SKMG and our company is responsible pursuant to Art. 17 Para. 1 DS-GVO, SKMG shall implement reasonable measures, including technical measures, to compensate other data controllers for processing the personal data published, taking into account the available technology and the cost of implementation, in order to inform the data subject that he or she has requested from those other data controllers to erase all links to the personal data or copies or replications of the personal data, unless the processing is necessary.<br />
                The employee of SKMG will arrange the necessary in individual cases.<br />
                <br />
                <br />
                <strong>[E] RIGHT TO RESTRICT PROCESSING.</strong> <br />
                Each data subject concerned by the processing of personal data has the right, granted by the European Directive and Regulation, to obtain from the controller the restriction of the processing if one of the following conditions is met:<br />
                <br />
                * The accuracy of the personal data is contested by the data subject for a period enabling the controller to verify the accuracy of the personal data.<br />
                * The processing is unlawful, the data subject objects to the erasure of the personal data and requests instead the restriction of the use of the personal data.<br />
                * The controller no longer needs the personal data for the purposes of the processing, but the data subject needs it for the assertion, exercise or defense of legal claims.<br />
                * The data subject has objected to the processing pursuant to Article 21 (1) of the GDPR and it is not yet clear whether the legitimate grounds of the controller override those of the data subject.<br />
                <br />
                If one of the aforementioned conditions is met, and a data subject wishes to request the restriction of personal data stored by SKMG, he or she may, at any time, contact any employee of the controller.<br />
                The employee of the SKMG will arrange the restriction of the processing.<br />


                <strong>[F] RIGHT TO DATA PORTABILITY</strong><br />
                Any person concerned by the processing of personal data has the right granted by the European Directive and Regulation to receive the personal data concerning him or her, which have been provided by the data subject to a controller, in a structured, commonly used and machine-readable format.<br />
                He or she also has the right to transmit this data to another controller without hindrance from the controller to whom the personal data have been provided, provided that the processing is based on consent pursuant to Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR or on a contract pursuant to Article 6(1)(b) of the GDPR and the processing is carried out by automated means, unless the processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the controller.<br />
                <br />   
                Furthermore, when exercising the right to data portability pursuant to Article 20(1) of the GDPR, the data subject shall have the right to obtain that the personal data be transferred directly from one controller to another controller where technically feasible and provided that this does not adversely affect the rights and freedoms of other individuals.<br />
                <br />
                In order to assert the right to data portability, the data subject may at any time contact any employee of SKMG.<br />
                <br />

                <strong>[G] RIGHT TO DISAGREE (Object)</strong><br />
                Any person affected by the processing of personal data has the right granted by the European Directive and Regulation to object at any time, on grounds relating to his or her particular situation, to the processing of personal data concerning him or her carried out on the basis of Article 6(1)(e) or (f) of the DS-GVO. This also applies to profiling based on these provisions.<br />
                <br />
                SKMG shall no longer process the personal data in the event of the objection, unless we can demonstrate compelling legitimate grounds for the processing which override the interests, rights and freedoms of the data subject, or for the assertion, exercise or defence of legal claims.<br />
                <br />
                If SKMG processes personal data for direct marketing purposes, the data subject shall have the right to object at any time to processing of personal data processed for such marketing. This also applies to profiling, insofar as it is related to such direct marketing.<br />
                <br />
                If the data subject objects to the SKMG to the processing for direct marketing purposes, SKMG will no longer process the personal data for these purposes.<br />
                <br />
                In addition, the data subject has the right, on grounds relating to his or her particular situation, to object to processing of personal data concerning him or her which is carried out by SKMG for scientific or historical research purposes, or for statistical purposes pursuant to Article 89(1) of the Data Protection Regulation (DS-GVO), unless such processing is necessary for the performance of a task carried out in the public interest.<br />
                <br />
                In order to exercise the right to object, the data subject may directly contact any employee of SKMG or another employee. The data subject is also free to exercise his/her right to object by means of automated procedures using technical specifications in connection with the use of information society services, notwithstanding Directive 2002/58/EC.<br />

                <br />
                <strong>[H] AUTOMATED DECISIONS IN INDIVIDUAL CASES INCLUDING PROFILING</strong><br />
                Any data subject concerned by the processing of personal data shall have the right, granted by the European Directive and the Regulation, not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning him or her or similarly significantly affects him or her, unless the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the controller, or (2) is permitted by Union or Member State law to which the controller is subject and that law contains suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, or (3) is made with the data subject's explicit consent.<br />
                <br />
                If the decision (1) is necessary for entering into, or the performance of, a contract between the data subject and the data controller, or (2) it is made with the data subject's explicit consent, SKMG shall implement suitable measures to safeguard the data subject's rights and freedoms and legitimate interests, which include at least the right to obtain the data subject's involvement on the part of the controller, to express his or her point of view and contest the decision.<br />
                <br />
                If the data subject wishes to exercise the rights concerning automated decisions, he or she may, at any time, contact any employee of the controller.<br />
                <br />

                <strong>[I] RIGHT TO WITHDRAW CONSENT UNDER DATA PROTECTION LAW.</strong>
                Every data subject affected by the processing of personal data has the right, granted by the European Directive and Regulation-maker, to withdraw consent to the processing of personal data at any time.<br />
                <br />
                If the data subject wishes to exercise the right to withdraw consent, he or she may, at any time, contact any employee of the controller.<br />
                <br />


                <br />
                <strong>20. LEGAL BASIS OF PROCESSING</strong><br />
                Article 6 I lit. a DS-GVO serves as the legal basis for our company for processing operations in which we obtain consent for a specific processing purpose.<br />
                If the processing of personal data is necessary for the performance of a contract to which the data subject is a party, as is the case, for example, with processing operations that are necessary for a delivery of goods or the provision of another service or consideration, the processing is based on Article 6 I lit. b DS-GVO.<br />
                The same applies to such processing operations that are necessary for the implementation of pre-contractual measures, for example in cases of inquiries about our products or services.<br />
                If our company is subject to a legal obligation by which a processing of personal data becomes necessary, such as for the fulfillment of tax obligations, the processing is based on Art. 6 I lit. c DS-GVO.<br />
                <br />
                In rare cases, the processing of personal data might become necessary to protect vital interests of the data subject or another natural person.<br />
                This would be the case, for example, if a visitor were to be injured on our premises and as a result his or her name, age, health insurance data or other vital information had to be passed on to a doctor, hospital or other third party.<br />
                Then the processing would be based on Art. 6 I lit. d DS-GVO. Finally, processing operations could be based on Art. 6 I lit. f DS-GVO. Processing operations that are not covered by any of the aforementioned legal bases are based on this legal basis if the processing is necessary to protect a legitimate interest of our company or a third party, provided that the interests, fundamental rights and freedoms of the data subject are not overridden.<br />
                Such processing operations are permitted to us in particular because they were specifically mentioned by the European legislator. In this respect, it took the view that a legitimate interest could be assumed if the data subject is a customer of the controller (recital 47 sentence 2 DS-GVO).<br />
                

                <br />
                <strong>21. LEGITIMATE INTERESTS IN PROCESSING PURSUED BY THE CONTROLLER OR A THIRD PARTY.</strong><br />
                If the processing of personal data is based on Article 6 I lit. f DS-GVO, our legitimate interest is the conduct of our business for the benefit of the well-being of all our employees and our shareholders.<br />
                <br />

                <strong>22. DURATION FOR WHICH THE PERSONAL DATA ARE STORED</strong>
                <br />The criterion for the duration of the storage of personal data is the respective statutory retention period. After expiry of the period, the corresponding data will be routinely deleted, provided that they are no longer required for the fulfillment or initiation of the contract.<br />
                <br />

                <strong>23. LEGAL OR CONTRACTUAL REQUIREMENTS FOR THE PROVISION OF PERSONAL DATA; NECESSITY FOR THE CONCLUSION OF THE CONTRACT; OBLIGATION OF THE DATA SUBJECT TO PROVIDE THE PERSONAL DATA; POSSIBLE CONSEQUENCES OF FAILURE TO PROVIDE THE DATA.</strong><br />
                We inform you that the provision of personal data is sometimes required by law (e.g. tax regulations) or may also result from contractual regulations (e.g. information on the contractual partner). Sometimes, in order to conclude a contract, it may be necessary for a data subject to provide us with personal data that must subsequently be processed by us.<br />
                <br />
                For example, the data subject is obliged to provide us with personal data if our company concludes a contract with him or her. Failure to provide the personal data would mean that the contract with the data subject could not be concluded.<br />
                <br />
                Before providing personal data by the data subject, the data subject must contact one of our employees. Our employee will explain to the data subject on a case-by-case basis whether the provision of the personal data is required by law or contract or is necessary for the conclusion of the contract, whether there is an obligation to provide the personal data, and what the consequences of not providing the personal data would be.<br />
                <br />

                <strong>24. EXISTENCE OF AUTOMATED DECISION-MAKING</strong><br />
                As a responsible company, we do not use automated decision making or profiling.<br />


                <br /><br />
                <b>What data do we collect?</b> <br />
                We collect personal data that you provide to us when you submit a contact form or request for a project estimate. This data may include your name, email address, phone number, and any other details you choose to provide.
                <br /> <br />
                <b>How do we use your data?</b>  <br />
                We use your personal data to respond to your inquiries, provide our services, and manage our contractual relationship with you.
                <br /> <br />
                <b>Cloudflare</b><br />
                As mentioned before, our website uses services provided by Cloudflare, Inc. 101 Townsend St, San Francisco, CA 94107, USA. Cloudflare is an American company that provides a content delivery network (CDN), security features, and distributed domain name server services, aimed at enhancing the performance and security of websites. These services work by the storage of website content at various locations around the world to improve website load speeds, alongside providing security measures that protect websites from different kinds of attacks, such as Distributed Denial of Service (DDoS) attacks.<br />
                <br />
                The key goal of integrating these services into our website is to ensure the protection and integrity of data, to enhance the security of our website, and to guarantee an optimal browsing experience for users. The information processed may include your IP address and other information about your behavior on this website.<br />
                <br />
                More information about Cloudflare and its data protection measures can be found at: <a href="https://www.cloudflare.com/privacypolicy/">https://www.cloudflare.com/privacypolicy/</a><br />
                <br />
                <b>Your rights</b>  <br />
                You have the right to access, correct, or delete your personal data. You also have the right to restrict or object to our processing of your data. To exercise any of these rights, please contact us at [Your Contact Information].
            <br/> <br />
                <b>Changes to this Policy</b>  <br />
                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the new Privacy Policy on our website.
            <br /><br /><br />
                Last updated: 13. July 2023
                <br /> <br />
        <b>Questions left? Contact us anytime.</b>
      </p>
      <br />

      <VuiButton sx={{ width: "100px", borderRadius: "50px"}}>Contact</VuiButton>
        </>
    );
    }
    
    export default PrivacyPolicyContent;