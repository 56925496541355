
import VuiButton from "components/VuiButton";
import VuiTypography from "components/VuiTypography";

function TermsOfUseContent () {

    return (
        <>
        <p style={{ color: "white" }}>
        <br />
        <strong>General Terms and Conditions of SKMG</strong><br />
        <strong>§1 Applicability to entrepreneurs and definitions of terms</strong><br />
        <br />
        (1) The following General Terms and Conditions of Business shall apply to all deliveries between us and a consumer or legal person or entrepreneur or company in the version valid at the time of the order (after conclusion of the contract, an inquiry does not constitute a contract).<br />
        <br />
        A consumer is any natural person who enters into a legal transaction for purposes that can predominantly be attributed neither to his commercial nor to his independent professional activity (§ 13 BGB).<br />
        <br />
        <strong>§2 Conclusion of a contract, storage of the contract text</strong><br />
        <br />
        (1) The following regulations on the conclusion of the contract apply to orders and contract conclusions via our website https://seankmg.de.<br />
        <br />
        (2) In the event of the conclusion of a contract, the contract is concluded with:<br />
        <br />
        SKMG<br />
        SEAN GAENICKE<br />
        ALTSTR. 9<br />
        51063 COLOGNE<br />
        GERMANY<br />
        <br />
        <br />
        (3) The presentation of the goods in our internet store does not represent a legally binding contract offer on our part, but is only a non-binding invitation to the consumer to order goods or send an inquiry. With the final order or acceptance of the offer of the desired goods, the consumer makes a binding offer for him to conclude a purchase contract.<br />
        <br />
        The acceptance of the offer is made in writing or in text form or by sending the ordered goods. After fruitless expiry of the deadline, the offer shall be deemed to have been rejected.<br />
        <br />
        (4) Upon receipt of an order in our Internet store, the following provisions shall apply: The consumer makes a binding offer to enter into a contract by successfully going through the order procedure provided in our Internet store (a request for an offer does not constitute a final purchase or conclusion of a contract).<br />
        <br />
        The order is placed in the following steps:<br />
        <br />
        [1] Selection of the desired goods<br />
        [2] Confirmation by clicking the "Order" button<br />
        [3] checking the data in the shopping cart<br />
        [4] pressing the "checkout" button<br />
        [5] Login to the Internet store after registration and entering the login data (e-mail address and password) (optional)<br />
        [6] Checking again or correcting the respective entered data<br />
        [7] Binding sending of the order by clicking the button "order with costs" or "buy"<br />
        <br />
        Before the binding submission of the order, the consumer can, after checking his details, return to the Internet page on which the customer's details are recorded and correct input errors or cancel the order process by closing the Internet browser by pressing the "Back" button contained in the Internet browser used by him.<br />
        We confirm receipt of the order immediately by an automatically generated e-mail (confirmation of receipt). This does not constitute acceptance of the offer. The acceptance of the offer is made in writing, in text form or by sending the ordered goods.<br />
        <br />
        (5) Storage of the contract text for orders via our Internet store : We store the contract text and send you the order data and our terms and conditions by e-mail. You can also view the GTC at any time at https://seankmg.com/about. <br />
        <br />
       
        <strong>§3 Prices, shipping costs, payment, due date</strong><br />
        <br />
        (1) The stated prices include the statutory sales tax and other price components. In addition, there are any possible shipping costs.<br />
        <br />
        (2) The consumer has the option of payment in advance, direct debit, PayPal, credit card (Visa, Mastercard, American Express), etc..<br />
        <br />
        (3) If the consumer has chosen payment in advance, he undertakes to pay the purchase price immediately after conclusion of the contract.<br />
       
        <strong>§4 Delivery</strong><br />
        (1) Unless we have clearly stated otherwise in the product description, all items offered by us aren't ready for immediate shipment. The delivery takes place here at the latest within 14 working days, if no delivery date is specified. Otherwise the delivery will take place after the minimum specified time frame in the contract, unless otherwise mentioned in the product description or in the active contract. Please allow for the minimum specified time frame for the delivery of items. In the case of payment in advance, the delivery period begins on the day after the payment order to the bank responsible for the transfer, and for all other payment methods on the day after the conclusion of the contract. If the deadline falls on a Saturday, Sunday or public holiday at the place of delivery, the deadline ends on the next working day.<br />
        <br />
        (2) The risk of accidental loss and accidental deterioration of the sold item shall not pass to the Buyer until the item is handed over to the Buyer, even in the case of a sale by dispatch.<br />
        <br />
        <strong>§5 Retention of title</strong><br />
        <br />We retain title to the goods until the purchase price has been paid in full.<br />
        <br />
        <br />

        <strong>****************************************************************************************************</strong><br />
        <strong>§6 Right of withdrawal of the customer as a consumer:</strong><br />
        <b>Right of withdrawal for consumers</b><br />
        <br />
        <br />Consumers are entitled to a right of revocation according to the following provisions, whereby a consumer is any natural person who enters into a legal transaction for purposes that can predominantly be attributed neither to his commercial nor to his independent professional activity:<br />
        <br />
        <b>Cancellation policy</b> <br />
        <i>Right of withdrawal</i><br />
        <br />
        You have the right to revoke this contract within fourteen days without giving any reason.<br />
        <br />
        The revocation period is fourteen days from the day on which you or a third party named by you, who is not the carrier, has or has taken possession of the goods.<br />
        <br />
        <b>To exercise your right of withdrawal, you must contact us:</b><br />
        <br />
        SKMG<br />
        SEAN GAENICKE<br />
        ALTSTR. 9<br />
        51063 COLOGNE<br />
        GERMANY<br />
        EMAIL: sean@musizel.com<br />
        <br />
        by means of a clear statement (eg a letter sent by mail, fax or e-mail) about your decision to revoke this contract.<br />
        You can use the attached sample cancellation form for this purpose, which is, however, not mandatory.<br />
        <br />

        <br />
        <strong>Consequences of cancellation</strong>
        <br />
        If you revoke this contract, we shall reimburse you all payments we have received from you, including delivery costs (with the exception of additional costs resulting from the fact that you have chosen a type of delivery other than the most favorable standard delivery offered by us), without undue delay and no later than within fourteen days from the day on which we received the notification of your revocation of this contract.<br />
        For this repayment, we will use the same means of payment that you used for the original transaction, unless expressly agreed otherwise with you; in no case will you be charged any fees because of this repayment.<br />
        <br /> 
        * We may refuse repayment until we have received the goods back or until you have provided proof that you have returned the goods, whichever is the earlier.<br />
        <br />
        * You must return or hand over the goods to us without undue delay and in any case no later than within fourteen days from the day on which you notify us of the revocation of this contract.<br />
        The deadline is met if you send the goods before the expiry of the period of fourteen days.<br />
        <br />
        * You shall bear the direct costs of returning the goods.<br />
        <br />
        * You will only have to pay for any loss in value of the goods if this loss in value is due to handling of the goods that is not necessary for testing the condition, properties and functioning of the goods.<br />
        <br />


        <strong>Exclusion or premature expiry of the right of revocation</strong><br />
        The right of withdrawal expires prematurely in the case of delivery of goods that are not suitable for return for reasons of health protection or hygiene.<br />
        Furthermore note, that digital products, which are instantly received after purchase, are non-refundable and cannot be returned once the purchase is successfully completed.<br />
        <br />
 
        <b>General notes</b><br />
        <br />
        [1] Please avoid damage and contamination of the goods. Please return the goods to us in the original packaging with all accessories and with all packaging components. If necessary, use a protective outer packaging.<br />
        If you no longer have the original packaging, please provide adequate protection against transport damage with suitable packaging.<br />
        <br />
        [2] Please do not return the goods to us freight collect.<br />
        <br />
        [3] Please note that the aforementioned items 1-2 are not a prerequisite for the effective exercise of the right of withdrawal, but can be.<br />
        <br />

        <strong>Financed transactions</strong><br />
        <br />
        If you have financed this contract by a loan and revoke the financed contract, you are also no longer bound to the loan contract if both contracts form an economic unit.<br />
        This is to be assumed in particular if we are also your lender or if your lender makes use of our cooperation with regard to the financing.<br />
        If the loan has already accrued to us when the revocation or return becomes effective, your lender shall be subrogated to our rights and obligations under the financed contract in relation to you with regard to the legal consequences of the revocation or return.<br />
        The latter shall not apply if the subject matter of the present contract is the acquisition of securities, foreign currencies, derivatives or precious metals.<br />
        <br />
        If you wish to avoid a contractual commitment as far as possible, revoke both contractual declarations separately.<br />
        <br />

        <strong>§7 Cancellation form</strong><br />
        <b><i>Sample revocation form</i></b><br />
        <br />
        (If you want to revoke the contract, please fill out this form and send it back).<br />
        <br />
        To :<br />
        SKMG<br />
        SEAN GAENICKE<br />
        ALTSTR. 9<br />
        51063 COLOGNE<br />
        GERMANY<br />
        EMAIL: sean@musizel.com<br />
        <br />
        I/we (*) hereby revoke the contract concluded by me/us (*) for the purchase of the following goods (*)/provision of the following service (*)<br />
        <br />
        _____________________________________________________<br />
        <br />

        Ordered on (*)/received on (*)<br />
        <br />
        __________________<br />

        <br />
        Name of the consumer(s)<br />
        <br />
        _____________________________________________________<br />
        <br />
        Address of consumer(s)<br />
        <br />
        _____________________________________________________<br />
        <br />
        Signature of the consumer(s) (only in case of paper communication)<br />
        <br />
        __________________<br />
        <br />

        Date<br />
        <br />
        __________________<br />
        <br />

        (*) Delete where inapplicable.<br />
        <br /><br/>

        <strong>§8 Warranty</strong><br />
        <br/>
        The legal warranty regulations apply.<br />
        <br />

        <strong>§9 Contract language</strong><br />
        <br />
        English or German shall be the sole language of the contract.<br />

        <br />

        <strong>§10 Copyright</strong><br />
        <i>In addition to Imprint</i><br />
        The contents and works created by the site operators on these pages are subject to German copyright law. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of this site are only permitted for private, non-public, non-commercial use.<br />
        <br />
        The copyrights for all products, services and other digital content on our SKMG platform are being held by SKMG and those are not allowed to be published for commercial neither non-commercial usage nor distribution without prior explicit agreement from SKMG.<br />
        <br />
        Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.<br />

        <br /><br /><br />
                Last updated: 13. July 2023
        <br /> <br />
        <b>Questions left? Contact us anytime.</b>
      </p>
      <br />

      <VuiButton sx={{ width: "100px", borderRadius: "50px"}}>Contact</VuiButton>
        </>
    );
    }
    
    export default TermsOfUseContent;