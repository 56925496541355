
import VuiButton from "components/VuiButton";

import seankmgLogo from "assets/images/seankmg_white_clear.png";

function ImprintContent() {

  return (
    <>
      <img src={seankmgLogo} style={{ width: "100px", marginLeft: "-15px" }} />
      <p style={{ color: "white" }}>
       <strong>Details according to § 5 TMG</strong> <br />
        SEAN GAENICKE <br />
        SKMG PERFORMANCE <br />
        C/O BLOCK SERVICES <br />
        STUTTGARTER STR. 106 <br />
        70736 FELLBACH <br />
        GERMANY <br />
        <br />
        TEL. [WA Business]: +49 1579 2611050 <br />
        EMAIL: sean@musizel.com <br />
        <br />
        VAT ID: DE352488766 <br />
        Registered Business in Germany.
        <br />
        <br />
        <strong>EU Dispute Resolution</strong> <br />

        The European Commission provides a platform for online dispute resolution (OS):<br />
        <br />
        https://ec.europa.eu/consumers/odr/ <br />
        <br />
        Our email address is listed at the beginning of the imprint. <br />
        <br /><br />

        <strong>Consumer dispute resolution / universal arbitration board</strong><br />
        <br />
        We are not willing or obliged to participate in dispute resolution proceedings before a consumer dispute resolution body.<br />

        <br />
        <br />
        <strong>Liability for contents</strong> <br />

        As a service provider, we are responsible for our own content on these pages in accordance with general legislation pursuant to Section 7 (1) of the German Telemedia Act (TMG).
        According to §§ 8 to 10 TMG, however, we are not obligated as a service provider to monitor transmitted or stored third-party information or to investigate circumstances that indicate illegal activity.<br />
        <br />
        Obligations to remove or block the use of information under the general laws remain unaffected. However, liability in this regard is only possible from the point in time at which a concrete infringement of the law becomes known. If we become aware of such infringements, we will remove this content immediately.<br />


        <br />
        <br />
        <strong>Liability for links</strong> <br />

        Our offer contains links to external websites of third parties, on whose contents we have no influence. Therefore, we cannot assume any liability for these external contents. The respective provider or operator of the pages is always responsible for the content of the linked pages. 
        The linked pages were checked for possible legal violations at the time of linking. Illegal contents were not recognizable at the time of linking.<br />
        <br />
        However, a permanent control of the contents of the linked pages is not reasonable without concrete evidence of a violation of the law. If we become aware of any infringements, we will remove such links immediately. <br />


        <br />
        <br />
        <strong>Copyright</strong> <br />
        The contents and works created by the site operators on these pages are subject to German copyright law. Duplication, processing, distribution, or any form of commercialization of such material beyond the scope of the copyright law shall require the prior written consent of its respective author or creator. Downloads and copies of this site are only permitted for private, non-public, non-commercial use.<br />
        <br />
        The copyrights for all products, services and other digital content on our SKMG platform are being held by SKMG and those are not allowed to be published for commercial neither non-commercial usage nor distribution without prior explicit agreement from SKMG.<br />
        <br />
        Insofar as the content on this site was not created by the operator, the copyrights of third parties are respected. In particular, third-party content is identified as such. Should you nevertheless become aware of a copyright infringement, please inform us accordingly. If we become aware of any infringements, we will remove such content immediately.<br />

        <br /><br /><br />
                Last updated: 13. July 2023
        <br /> <br />
        <b>Questions left? Contact us anytime.</b>
      </p>
      <br />

      <VuiButton sx={{ width: "100px", borderRadius: "50px"}}>Contact</VuiButton>
    </>
  );
}

export default ImprintContent;
