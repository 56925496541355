/** 

=========================================================
* Vision UI PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Visionware.

*/

import { useState, useEffect } from 'react';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Vision UI Dashboard PRO React components
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";

// Vision UI Dashboard PRO React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Vision UI Dashboard PRO React page layout routes
import pageRoutes from "page.routes";

// Images
import bgPricingPage from "assets/images/pricing-page-background.png";

import bg1 from "assets/images/background_header1.jpeg";
import bg2 from "assets/images/background_header2.jpeg";

// palette colors
import colors from "assets/theme/base/colors";
import linearGradient from "assets/theme/functions/linearGradient";
import borders from "assets/theme/base/borders";

import { IoCode, IoCodeSlash } from "react-icons/io5";

import seankmgLogo from "assets/images/seankmg_white_clear.png";


import PrivacyPolicyContent from "layouts/pages/about/components/legal_texts/privacy_policy";
import TermsOfUseContent from "layouts/pages/about/components/legal_texts/terms_of_use";
import ImprintContent from "layouts/pages/about/components/legal_texts/imprint";


function Header({ tabValue, tabHandler }) {
  const { gradients } = colors;
  const { cardContent } = gradients;
  const { borderRadius } = borders;

  const [tabValueData, setTabValueData] = useState({ heading: "", content: null, revised: "" });

  useEffect(() => {
    switch (tabValue) {
      case 0:
        setTabValueData({ heading: "Privacy Policy", content: <PrivacyPolicyContent/>, revised: "" });
        break;
      case 1:
        setTabValueData({ heading: "Terms of Use", content: <TermsOfUseContent/>, revised: "" });
        break;
      default:
        setTabValueData({ heading: "Imprint", content: <ImprintContent/>, revised: "" });
    }
  }, [tabValue]); 

  return (
    <>
      <DefaultNavbar
        routes={pageRoutes}
        action={{
          type: "external",
          //route: "#/",
          label: "inquiry",
          color: "dark",
        }}
        transparent
        light
      />
      <VuiBox
        //m={2}
        //pt={12}
        pb={0}
        position="relative"
        sx={{ height: { xs: "55vh", lg: "55vh", xl: "55vh", xxl: "55vh" } }}
        style={{
          overflow: "hidden",
          borderRadius: "0 0 1em 1em",
          overflowX: "hidden",
        }}>
        <VuiBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          style={{
            backgroundImage: `url(${bg1})`,
            backgroundSize: "cover",
            filter: "blur(15px)",
            webkitFilter: "blur(15px)",
            zIndex: 1,
            borderRadius: "1em",
          }}
        />
        <VuiBox
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          style={{
            background: "linear-gradient(transparent, black)",
            zIndex: 2,
            borderRadius: "1em",
          }}
        />

        <VuiBox position="relative" zIndex={3} pt={15} >
          <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
            <Grid item xs={10} lg={5}>
              <VuiBox mb={0} textAlign="center">
              <img src={seankmgLogo} style={{ width: "90px"}}/>
                <VuiTypography fontSize={32} color="white" fontWeight="bold">
                <span style={{ fontFamily: 'VT323, monospace', marginLeft: "1px"}}>{"{about}"}</span>
                </VuiTypography>
              </VuiBox>
              <VuiBox mb={0} textAlign="center" display="flex" justifyContent="center" flexDirection="row">
                <VuiTypography variant="body2" color="white" fontWeight="regular" sx={{ maxWidth: "340px" }}>
                <span style={{ fontFamily: 'VT323, monospace' }}>Having a question? Feel free to contact us.</span>
                </VuiTypography>
              </VuiBox>
              <Grid container item xs={12} sm={10} md={8} lg={7} sx={{ mx: "auto" }}>
                <VuiBox width="100%" mt={4} display="flex" justifyContent="center" alignItems="center"></VuiBox>
              </Grid>
            </Grid>
          </Grid>
        </VuiBox>
      </VuiBox>

      <VuiBox width="105rem" position="absolute" top={0} />
      <Grid container item xs={12} sm={12} md={8} lg={7} mt={-13} sx={{ mx: "auto" }}>
                <VuiBox width="100%" mt={5} display="flex" justifyContent="center" alignItems="center">
                  <AppBar position="static" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Tabs
                      value={tabValue}
                      onChange={tabHandler}
                      TabIndicatorProps={{ style: { borderRadius: "50px", backgroundColor: "white", color: "black" } }}
                      sx={{
                        width: "350px",
                        maxWidth: "350px",
                        zIndex: "1000",
                        backgroundImage: linearGradient(cardContent.main, cardContent.state, cardContent.deg),
                        borderRadius: "125px",
                        padding: "10px"
                      }}>
                      <Tab
                        id="privacy"
                        label={
                          <VuiBox py={0.5}>
                            <VuiTypography fontSize={12} color="purple" fontWeight="bold">
                              Privacy Policy
                            </VuiTypography>
                          </VuiBox>
                        }
                        sx={{ minWidth: 0, width: "33%" }}
                      />
                      <Tab
                        id="terms"
                        label={
                          <VuiBox py={0.5}>
                            <VuiTypography fontSize={12} color="purple" fontWeight="bold">
                              Terms of Use
                            </VuiTypography>
                          </VuiBox>
                        }
                        
                        sx={{ minWidth: 0, width: "33%" }}
                      />
                      <Tab
                        id="imprint"
                        label={
                          <VuiBox py={0.5}>
                            <VuiTypography fontSize={12} color="purple" fontWeight="bold">
                              Imprint
                            </VuiTypography>
                          </VuiBox>
                        }
                        sx={{ minWidth: 0, width: "33%" }}
                      />
                    </Tabs>
                  </AppBar>
                </VuiBox>
              </Grid>

              <VuiBox mt={{ sm: 10, xl: 16 }}>
              <Grid container justifyContent="center">
    <Grid item xs={12} lg={9} mb="40px" mt={{ xs: -5, sm: -5, md: -5, lg: -5, xl: -10, xxl: -10}}>
        <VuiTypography fontSize={32} color="white" align="center" fontWeight="bold" gutterBottom>
            { tabValueData.heading }
        </VuiTypography>
        <VuiBox mb={2}>
            <VuiBox width="100%" >
                <VuiTypography
                    fontSize={{xs: 15, sm: 15, md: 16, lg: 16, xl: 16, xxl: 16}}
                    fontWeight="regular"
                    color="white"
                    align="left"
                    color="text"
                >
                    <VuiBox px={{ xs: 3.5, sm: 3.5, md: 10, lg: 10, xl: 10, xxl: 10 }}> {tabValueData.content} </VuiBox>
                    {/*tabValueData.revised*/}
                </VuiTypography>
            </VuiBox>
        </VuiBox>
    </Grid>
</Grid>
        </VuiBox>



    </>
  );
}

// Typechecking props for the Header
Header.propTypes = {
  tabValue: PropTypes.number.isRequired,
  tabHandler: PropTypes.func.isRequired,
};

export default Header;
